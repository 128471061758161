import React from "react"
import { isIE } from "react-device-detect"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Header from "./header/header"
import Footer from "./footer"
import "../styles/components/layout.scss"

import ReactGA from "react-ga"

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isMobile: false }
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
    ReactGA.initialize("UA-154237086-1")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    if (window.innerWidth > 768 && this.state.isMobile) {
      this.setState({ isMobile: false })
    } else if (window.innerWidth <= 768 && !this.state.isMobile) {
      this.setState({ isMobile: true })
    }
  }

  render() {
    const backgroundSourcesBlue = [
      {
        ...this.props.data.backgroundImageBlueMobile.childImageSharp.fluid,
        media: `(max-width: 475px)`,
      },
      {
        ...this.props.data.backgroundImageBlue.childImageSharp.fluid,
        media: `(min-width: 476px)`,
      },
    ]
    const backgroundSourcesLight = [
      {
        ...this.props.data.backgroundImageLightMobile.childImageSharp.fluid,
        media: `(max-width: 475px)`,
      },
      {
        ...this.props.data.backgroundImageLight.childImageSharp.fluid,
        media: `(min-width: 476px)`,
      },
    ]

    if (isIE) {
      backgroundSourcesBlue.reverse()
      backgroundSourcesLight.reverse()
    }

    return (
      <div
        style={{
          minHeight: "100vh",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div className={"backgroundImageContainer"}>
          <Img
            className={"backgroundImage"}
            fluid={
              this.props.isHomePage
                ? backgroundSourcesBlue
                : backgroundSourcesLight
            }
            loading="eager"
            objectFit="cover"
            objectPosition="50% 88%"
          />
        </div>
        <Header
          isMobile={this.state.isMobile}
          isHomePage={this.props.isHomePage}
          location={this.props.location}
          //location is used to determine which page should be highligted in the Header
          //props.location.header needs to be sent from every page
        />
        <div
          style={{
            margin: `0 auto`,
            paddingTop: 0,
          }}
        >
          <main>{this.props.children}</main>
        </div>
        <Footer
          isMobile={this.state.isMobile}
          isHomePage={this.props.isHomePage}
          disclaimer={this.props.disclaimer}
        />
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImageBlue: file(
          relativePath: { eq: "images/background-nyc-highres.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundImageBlueMobile: file(
          relativePath: { eq: "images/background-nyc-highres.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 425) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundImageLight: file(
          relativePath: { eq: "images/background-nyc-light-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundImageLightMobile: file(
          relativePath: { eq: "images/background-nyc-light-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 425) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
