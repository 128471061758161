import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import DropdownHeader from "./dropdownHeader"
import MobileNav from "./mobileNav"
import styles from "../../styles/components/header/header.module.scss"

import IconHamburger from "../../images/icon-hamburger.svg"
import IconHamburgerBlue from "../../images/icon-hamburger-blue.svg"
import LogoColor from "../../images/berenson-logo-color.png";
import LogoWhite from "../../images/berenson-logo-white.png";

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

function LinkItem({ text, path, linkClass, location }) {
  //subPageMatch var keeps berenson-capital / advisory pages active on their sub-pages
  var subPageMatch = false
  if (linkClass === "mainLink") {
    subPageMatch =
      (path === "/berenson-capital/" &&
        location.includes("berenson-capital")) ||
      (path === "/berenson-advisory/" && location.includes("berenson-advisory"))
  } else if (linkClass === "otherLink") {
    subPageMatch = path === "/news/" && location.includes("news")
  }

  var active = path === location || subPageMatch

  return (
    <li>
      <Link
        className={cx(linkClass, {
          active: active,
          inactive: !active,
        })}
        to={path}
      >
        {text}
      </Link>
    </li>
  )
}

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = { mobileNav: false, homeNav: false }
    this.toggleMobileNav = this.toggleMobileNav.bind(this)
    this.toggleHomeNav = this.toggleHomeNav.bind(this)

    this.linkData = {
      //mainLinks aren't used much since there's too many specific cases
      //however, don't change the order of mainLinks
      mainLinks: [
        { path: "/berenson-advisory/", text: "Berenson Advisory" },
        { path: "/berenson-capital/", text: "Berenson Capital" },
      ],
      advisoryLinks: [
        { path: "/berenson-advisory/", text: "Focus" },
        { path: "/berenson-advisory/results/", text: "Results" },
      ],
      capitalLinks: [
        { path: "/berenson-capital/", text: "Focus" },
        { path: "/berenson-capital/portfolio/", text: "Portfolio" },
        { path: "/berenson-capital/operating-executives/", text: "Operating Executives" },
      ],
      otherLinks: [
        { path: "/team/", text: "Team" },
        { path: "/news/", text: "News" },
        { path: "/contact/", text: "Contact Us" },
      ],
    }
  }

  toggleMobileNav() {
    this.setState(state => ({ mobileNav: !state.mobileNav }))
  }

  toggleHomeNav() {
    this.setState(state => ({ homeNav: !state.homeNav }))
  }

  render() {
    if (typeof document !== "undefined") {
      let html = document.getElementsByTagName("html")[0]
      if (this.state.mobileNav || this.state.homeNav) {
        this.scrollTop = -html.getBoundingClientRect().top
        html.style.top = `${html.getBoundingClientRect().top}px`
        html.classList.add(styles.preventBodyScroll)
      } else {
        html.classList.remove(styles.preventBodyScroll)
        html.scrollTop = this.scrollTop
      }
    }

    var location = this.props.location
    var isHome = location === "/"
    var isCapital = location.includes("berenson-capital")
    var isAdvisory = location.includes("berenson-advisory")
    return (
      <header className={styles.header}>
        <div className={styles.logoSection}>
          <Link
            className={styles.logo}
            to="/"
            style={{
              textDecoration: "none",
              color: this.props.isHomePage ? "white" : "#1C5D7F",
            }}
          >
            <img 
              src={isHome ? LogoWhite : LogoColor}
              alt="Logo"
              width="200px"
              height="auto"
            />
          </Link>
          {(this.props.isMobile || isHome) && (
            <>
              {isHome ? (
                this.props.isMobile ? (
                  <div className={styles.buttonWrapper}>
                    <button
                      className={styles.button}
                      aria-label="Open navigation"
                      onClick={this.toggleMobileNav}
                    >
                      <IconHamburger />
                    </button>
                  </div>
                ) : (
                  <div className={styles.buttonWrapper}>
                    <button
                      className={styles.button}
                      aria-label="Open navigation"
                      onClick={this.toggleHomeNav}
                    >
                      <IconHamburger />
                    </button>
                  </div>
                )
              ) : (
                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.button}
                    aria-label="Open navigation"
                    onClick={this.toggleMobileNav}
                  >
                    <IconHamburgerBlue />
                  </button>
                </div>
              )}
              <div
                className={cx("mobileNavContainer", {
                  display: this.state.mobileNav,
                })}
              >
                <MobileNav
                  toggleNav={this.toggleMobileNav}
                  linkData={this.linkData}
                />
              </div>
              <div
                className={cx("overlay", { display: this.state.homeNav })}
                onClick={this.toggleHomeNav}
              />
              <div
                className={cx("homeNavContainer", {
                  display: this.state.homeNav,
                })}
              >
                <MobileNav
                  toggleNav={this.toggleHomeNav}
                  linkData={this.linkData}
                />
              </div>
            </>
          )}
        </div>
        {/*Mobile Bottom Nav*/}
        {this.props.isMobile && (isCapital || isAdvisory) && (
          <div className={cx("mobileNavBottom", { darkBlue: isCapital })}>
            {isAdvisory && (
              <Link to="/berenson-advisory/" className={styles.mobileMainLink}>
                Berenson Advisory
              </Link>
            )}
            {isCapital && (
              <Link to="/berenson-capital/" className={styles.mobileMainLink}>
                Berenson Capital
              </Link>
            )}
            <DropdownHeader
              isCapital={isCapital}
              isAdvisory={isAdvisory}
              location={location}
              advisoryLinks={this.linkData.advisoryLinks}
              capitalLinks={this.linkData.capitalLinks}
            />
          </div>
        )}
        {/*Desktop Right Nav*/}
        {!this.props.isMobile && !isHome && (
          <nav className={styles.navContainer}>
            <ul className={styles.navTop}>
              <LinkItem
                className={styles.lineAfter}
                text="Berenson Advisory"
                path="/berenson-advisory/"
                linkClass="mainLink"
                location={location}
              />
              {!isCapital && !isAdvisory && (
                <div className={styles.verticalLine} />
              )}
              <LinkItem
                text="Berenson Capital"
                path="/berenson-capital/"
                linkClass="mainLink"
                location={location}
              />
              {this.linkData.otherLinks.map((link, key) => (
                <LinkItem
                  text={link.text}
                  path={link.path}
                  linkClass="otherLink"
                  location={location}
                  key={key}
                />
              ))}
            </ul>
            {/*Only show bottom part of Nav if on capital/advisory pages*/}
            {(isCapital || isAdvisory) && (
              <ul className={cx("navBottom", { darkBlue: isCapital })}>
                {isAdvisory && (
                  <>
                    {this.linkData.advisoryLinks.map((link, key) => (
                      <LinkItem
                        text={link.text}
                        path={link.path}
                        linkClass="subLink"
                        location={location}
                        key={key}
                      />
                    ))}
                  </>
                )}
                {isCapital && (
                  <>
                    {this.linkData.capitalLinks.map((link, key) => (
                      <LinkItem
                        text={link.text}
                        path={link.path}
                        linkClass="subLink"
                        location={location}
                        key={key}
                      />
                    ))}
                  </>
                )}
              </ul>
            )}
          </nav>
        )}
      </header>
    )
  }
}

export default Header

Header.propTypes = {
  isHomePage: PropTypes.bool,
  location: PropTypes.string.isRequired,
}
