import React from "react"
import { Link } from "gatsby"

import styles from "../../styles/components/header/mobile-nav.module.scss"
import IconNavDropdown from "../../images/icon-nav-dropdown.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

class MobileNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false }

    this.mainLink = this.props.mainLink
    this.subLinks = this.props.subLinks
    this.toggleOpen = this.toggleOpen.bind(this)
  }

  toggleOpen() {
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    return (
      <>
        <div className={cx("mainLinkContainer", { open: this.state.open })}>
          <button
            className={styles.mainLinkOverlay}
            aria-label="Open Sublinks"
            onClick={this.toggleOpen}
          />
          {this.mainLink.text}

          <IconNavDropdown
            className={cx({ open: this.state.open })}
            onClick={this.toggleOpen}
          />
        </div>
        <div className={cx("dropdown", { open: this.state.open })}>
          <ul>
            {this.subLinks.map((link, key) => (
              <li className={styles.subLinkContainer} key={key}>
                <Link to={link.path} onClick={this.props.toggleNav}>
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </>
    )
  }
}

export default MobileNav
