import React from "react"
import { Link } from "gatsby"
import styles from "../styles/components/footer.module.scss"

class Footer extends React.Component {
  render() {
    const disclaimer = this.props.disclaimer
    return (
      <>
        {/*Mobile Footer*/}
        {this.props.isMobile && !this.props.isHomePage && (
          <>
            <div className={styles.mobilePlaceholder} />
            <footer className={styles.mobileFooter}>
              {disclaimer && (
                <p
                  className={styles.footerText}
                  style={{ marginBottom: 32, lineHeight: "normal" }}
                >
                  {disclaimer}
                </p>
              )}
              <Link to={"/"} className={styles.homeLink}>
                Berenson
              </Link>
              <ul className={styles.linkList}>
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/berenson-advisory"}>Berenson Advisory</Link>
                </li>
                <li>
                  <Link to={"/berenson-capital"}>Berenson Capital</Link>
                </li>
                <li>
                  <Link to={"/team"}>Team</Link>
                </li>
                <li>
                  <Link to={"/news"}>News</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                </li>
              </ul>

              <p className={styles.footerText}>
                © {new Date().getFullYear()} Berenson All Rights Reserved.
              </p>
              <p className={styles.footerText}>
                The services represented herein are offered through Berenson &
                Company, LLC, a wholly owned subsidiary of Berenson & Company,
                Inc.
              </p>
              <div className={styles.outboundLinks}>
                <a
                  href="https://www.finra.org/#/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FINRA
                </a>
                <a
                  href="https://www.sipc.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIPC
                </a>
              </div>
            </footer>
          </>
        )}

        {/*Desktop Footer*/}
        {(!this.props.isMobile || this.props.isHomePage) && (
          <>
            {/* creates space for the footer in the DOM */}
            <div className={styles.placeholder} />
            <footer className={styles.desktopFooter}>
              <div
                style={{
                  color: this.props.isHomePage ? "white" : "#201E5A",
                  maxWidth: this.props.isMobile && "300px",
                }}
              >
                {disclaimer && (
                  <p className={styles.footerText}>{disclaimer}</p>
                )}
                <p className={styles.footerText}>
                  © {new Date().getFullYear()} Berenson All Rights Reserved.
                </p>
                <p className={styles.footerText}>
                  The services represented herein are offered through Berenson &
                  Company, LLC, a wholly owned subsidiary of Berenson & Company,
                  Inc.
                </p>
                <div className={styles.outboundLinks}>
                  <a
                    href="https://www.finra.org/#/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FINRA
                  </a>
                  <a
                    href="https://www.sipc.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SIPC
                  </a>
                </div>
              </div>
            </footer>
          </>
        )}
      </>
    )
  }
}

export default Footer
