import React from "react"
import { Link } from "gatsby"

import MainLinkDropdown from "./mainLinkDropdown"

import styles from "../../styles/components/header/mobile-nav.module.scss"
import headerStyles from "../../styles/components/header/header.module.scss"
import IconClose from "../../images/close-icon-white.svg"
import LogoWhite from "../../images/berenson-logo-white.png";

class MobileNav extends React.Component {
  constructor(props) {
    super(props)

    this.linkData = this.props.linkData
  }

  render() {
    return (
      <>
        <div className={styles.logoSection}>
          <Link
            className={headerStyles.logo}
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
            }}
            onClick={this.props.toggleNav}
          >
            <img 
              src={LogoWhite}
              alt="Logo"
              width="140px"
              height="auto"
            />
          </Link>
          <button aria-label="Close" onClick={this.props.toggleNav}>
            <IconClose />
          </button>
        </div>
        <nav className={styles.linksContainer}>
          <ul>
            <li>
              <MainLinkDropdown
                mainLink={this.linkData.mainLinks[0]}
                subLinks={this.linkData.advisoryLinks}
                toggleNav={this.props.toggleNav}
              />
            </li>
            <li>
              <MainLinkDropdown
                mainLink={this.linkData.mainLinks[1]}
                subLinks={this.linkData.capitalLinks}
                toggleNav={this.props.toggleNav}
              />
            </li>
            {this.linkData.otherLinks.map((link, key) => (
              <li className={styles.otherLinkContainer} key={key}>
                <Link to={link.path} onClick={this.props.toggleNav}>
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </>
    )
  }
}

export default MobileNav
